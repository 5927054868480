@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.Intro {
  background-color: $black;
  height: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  padding-bottom: 80px;
  width: 100%;

  @include md {
    padding-bottom: 0;
  }
}

.Intro-title {
  animation: fadeout 6000ms ease-in-out forwards;
  color: $white;
  padding: 143px 25px 0 25px;
  width: 255px;

  @include md {
    padding: 160px 35px 0 35px;
    width: 460px;
  }

  @include xl {
    padding: 200px 0 0 100px;
    width: 100%;
  }

  .Header1 {
    margin-bottom: 22px;
    text-transform: uppercase;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    visibility: visible;
  }

  50% {
    opacity: 0.3;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.Intro-title--hide {
  display: none;
}

.Intro-select-section {
  color: $white;
  opacity: 0;
  transition: all 2500ms ease-in-out;
  visibility: hidden;
}

.Intro-select-section-show {
  height: 100%;
  opacity: 1;
  visibility: visible;

  @include xl {
    overflow-y: scroll;
  }
}

.Intro-text {
  @include md {
    display: block;
    width: 300px;
  }

  @include xl {
    width: auto;
  }
}