@import "scss/base/breakpoints";
@import "scss/base/colors";

.SpreadTheWord {
  @include xl {
    display: flex;
    height: 100vh;
    padding-bottom: 0;
  }

  #can_embed_form_inner h2,
  h4 {
    display: none;
  }

  #logo_wrap {
    display: none;
  }

  #action_info {
    display: none;
  }

  #form_col1 {
    input {
      font-family: "HelveticaNeue" !important;
      padding-left: 20px !important;
    }
  }

  #form_col1 {
    input {
      height: 60px !important;
    }

    .checkbox_group_wrap {
      input {
        height: 20px !important;
      }
    }
  }

  #can_embed_form.can_float #form_col1,
  #can_embed_form.can_float #form_col2 {
    @include md {
      width: 100% !important;
    }
  }

  #can_embed_form .country_drop_wrap select.can_select {
    height: 60px !important;
  }

  #can_embed_form .floatlabel-wrapper .floatlabel-label {
    color: $medium-gray !important;
  }

  #can_embed_form select.can_select,
  #can_embed_form span.can_select {
    align-items: center;
    background-position: right -40px top 17px !important;
    display: flex !important;
    height: 60px !important;
    padding: 0 0 0 20px !important;
  }

  #form_col2 {
    display: flex;
    flex-direction: column;

    input {
      background-color: $black !important;
      font-size: 20px !important;
      line-height: 20px !important;
      order: 2;
    }
  }

  #can_embed_form #d_sharing {
    border-top: 0 !important;
  }

  #can_embed_form ul,
  #can_embed_form ol {
    margin: 0 !important;
  }

  #can_embed_form #d_sharing label {
    color: $black !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-bottom: 32px !important;
    margin-top: 20px !important;
    padding-left: 35px !important;
    position: static !important;

    @include xl {
      margin-bottom: 60px !important;
    }
  }

  #can_embed_form #form_col2 label {
    align-items: center !important;
    display: flex !important;
    font-family: "HelveticaNeue" !important;
  }

  #can_embed_form #form_col2 select,
  #can_embed_form #form_col2 input {
    margin-bottom: 0 !important;

    @media (hover: hover) {
      &:hover {
        background-color: $dark-gray !important;
      }
    }
  }

  #can_embed_form #d_sharing input {
    height: 24px !important;
    width: 24px !important;
  }

  #can_embed_form .check_radio_field label {
    margin-bottom: 20px !important;
    padding: 0 0 0 37px !important;
  }

  #form_col2 input {
    height: 60px;
  }

  #can_embed_form .control-label {
    display: none !important;
  }

  #can_embed_form .check_radio_field label input {
    border-radius: 50% !important;
    display: block !important;
    height: 24px !important;
    margin-right: 12px !important;
    top: 0 !important;
    width: 24px !important;
  }

  #can_embed_form #form_col2 input {
    @include xl {
      margin-bottom: 120px !important;
    }
  }

  #can_embed_form {
    border: 0 !important;
    padding: 32px 25px 40px 25px !important;

    @include md {
      padding: 40px 35px 64px 35px !important;
    }

    @include xl {
      padding: 0 !important;
      width: 400px !important;
    }
  }

  #form_col2 input {
    font-family: Knockout !important;
  }
}

.has-tabbed .SpreadTheWord #can_embed_form #form_col2 input:focus {
  outline: 1px solid $black !important;
  outline-offset: 4px !important;
}

.Spread-form {
  @include xl {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.SpreadTheWord-involved {
  margin-top: 40px;
  padding-left: 25px;
  width: 255px;

  @include md {
    padding-left: 35px;
    width: 100%;
  }

  @include xl {
    padding-left: 0;
  }
}

.SpreadTheWord-involved-name {
  &:first-letter {
    text-transform: uppercase;
  }

  @include xl {
    margin-bottom: 30px;
    display: block;
  }
}

.SpreadTheWord-socials-involved-wrapper {
  @include xl {
    margin-left: 630px;
    padding-top: 160px;
    width: calc(100% - 630px);
  }
}

.SpreadTheWord-socials-involved-inner-wrapper {
  .DownloadBox-button {
    display: none;
  }

  @include xl {
    margin: 0 auto;
    width: 400px;

    .DownloadBox-button {
      display: block;
    }
  }
}

.SpreadTheWord-involved-label {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;

  @include md {
    margin-bottom: 12px;
  }
}