@import "scss/base/colors";
@import "scss/base/breakpoints";

.Home-dropdown {
  box-shadow: 0px 1px 0px rgba($black, 0.1);
  height: 68px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  @include md {
    box-shadow: none;
  }

  @include xl {
    height: 80px;
    width: auto;
  }
}

.Home-dropdown--white {
  box-shadow: 0px 1px 0px rgba($white, 0.1);

  @include md {
    box-shadow: none;
  }
}

.Home-middle-section {
  @include md {
    margin: 0 auto;
    width: 520px;
  }

  @include xl {
    display: flex;
    padding: 40px 100px 0 100px;
    width: auto;
  }
}

.Home-slider {
  height: 443px;

  @include md {
    height: 570px;
  }

  @include xl {
    height: 580px;
  }

  &:before {
    box-shadow: 0px 1px 0px rgba($black, 0.1);
    content: "";
    display: block;
    height: 1px;
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 68px;
    width: 100vw;
    z-index: 10;

    @include md {
      display: none;
    }

    @include xl {
      display: block;
      top: 80px;
    }
  }
}

.Home-slider--white::before {
  box-shadow: 0px 1px 0px rgba($white, 0.1);
}