@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.DownloadBoxModal {
  display: none;

  @include xl {
    position: absolute;
    right: 100px;
    top: 80px;
    background-color: $white;
    z-index: 10;
    padding: 32px 40px 40px 40px;
    width: 482px;
  }
}

.DownloadBoxModal--opened {
  @include xl {
    display: block;
  }
}
