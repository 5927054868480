@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.Footer {
  padding: 40px 0 60px 25px;
  background-color: $light-gray;

  @include md {
    padding: 40px 115px 130px 35px;
  }

  @include xl {
    padding: 40px 0 80px 100px;
  }
}

.Footer-inner {
  width: 280px;
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}

.Footer-link {
  font-weight: 800;
  color: $black;
  text-decoration: underline;
  display: inline-block;
}

.Footer-title {
  text-transform: uppercase;
  margin-bottom: 20px;
  display: block;
  margin-top: 60px;

  @include md {
    font-size: 24px;
    line-height: 24px;
  }

  @include xl {
    margin-top: 0;
  }
}

.Footer-email {
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
}

.Footer-email-link {
  color: $black;
  text-decoration: underline;
  margin-bottom: 20px;
  display: block;
}

.Footer-text {
  display: block;
  margin-bottom: 20px;
}

.Footer-tel {
  display: inline-block;
  font-weight: 700;
  text-decoration: underline;
}

.Footer-logo {
  margin-top: 40px;

  @include xl {
    margin-bottom: 0;
  }
}

.Footer-text-wrapper {
  width: 100%;

  @include md {
    width: 360px;
  }

  @include xl {
    width: 50%;
    padding-right: 230px;
  }
}

.Footer-tel-socials-wrapper {
  @include xl {
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding-right: 200px;
  }
}

.Footer-tel-wrapper {
  margin-bottom: 60px;

  @include md {
    margin-bottom: 50px;
  }

  @include xl {
    margin-bottom: 0;
  }

  @include xl {
    margin-right: 125px;
  }
}

.Footer-email,
.Footer-tel,
.Footer-link {
  margin-left: -4px;
  padding: 0 4px;

  @media (hover: hover) {
    &:hover {
      color: $dark-gray;
    }
  }
}

.has-tabbed {

  .Footer-email-link,
  .Footer-link {
    &:focus {
      outline: 1px solid $black !important;
      outline-offset: 4px;
      border: 0;
    }
  }
}

.Footer-email,
.Footer-tel {
  @include md {
    font-size: 16px;
    line-height: 24px;
  }

  @include xl {
    font-size: 18px;
    line-height: 28px;
  }
}