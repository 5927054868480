@import "scss/base/breakpoints";

.CheckRegistration {
  padding-bottom: 35px;

  @include xl {
    display: flex;
    height: 100%;
    min-height: 100vh;
    padding-bottom: 0;
  }
}

.CheckRegistration-form {
  margin-bottom: 40px;
  margin-top: 32px;

  @include md {
    padding: 0 9px;
  }

  @include xl {
    margin-left: auto;
    padding: 160px 200px 180px 200px;
    width: calc(100% - 630px);
  }
}

.CheckRegistration-form--completed {
  padding-top: 100px;

  @include md {
    padding-top: 160px;
    padding-left: 9px;
  }

  @include xl {
    padding: 160px 0 0 75px;
    width: 75%;
  }
}

.CheckRegistration-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;

  @include xl {
    align-items: center;
    bottom: 40px;
    position: fixed;
    right: 40px;
  }
}

.CheckRegistration-form-copy {
  display: none;

  @include xl {
    display: block;
    margin-bottom: 40px;
    padding-left: 25px;
  }
}

.CheckRegistration-form--completed .Register-form-copy {
  display: none;
}