@import "scss/base/breakpoints.scss";
@import "scss/base/colors.scss";

.Footer-socials-wrapper {
  display: flex;

  @include xl {
    width: 150px;
  }
}

.Footer-instagram {
  margin: 0 8px;
}

.FooterSocials-label {
  display: block;
  text-transform: uppercase;
  margin-bottom: 20px;

  @include md {
    font-size: 24px;
    line-height: 24px;
  }
}

.Footer-social {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid $black;

  @media (hover: hover) {
    &:hover {
      background-color: $black;

      svg path {
        fill: $white;
      }
    }
  }
}

.has-tabbed .Footer-social:focus {
  outline: 0;

  &:after {
    content: '';
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid $black;
    position: absolute;
  }
}