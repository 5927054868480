@import "scss/base/colors";
@import "scss/base/breakpoints";

.Status {
  @include xl {
    display: flex;
    height: 100vh;
    padding-bottom: 0;
  }
}

.Status-link-wrapper {
  padding: 30px 25px 0 25px;

  @include md {
    padding: 0 35px;
  }

  @include xl {
    margin: 0 auto;
    padding: 160px 0 0 0;
    width: 400px;
  }
}

.Status-link--hasBorder {
  border-bottom: 1px solid $light-gray;
}

.Status-text {
  display: none;

  @include xl {
    display: block;
    margin-bottom: 15px;
  }
}

.Status-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;

  @include xl {
    bottom: 40px;
    position: absolute;
    right: 40px;
  }
}

.Status-link-container {
  padding-bottom: 25px;

  @include xl {
    display: flex;
    flex-direction: column;
    margin-left: 630px;
    width: calc(100% - 630px);
  }
}