@import "scss/base/colors";
@import "scss/base/breakpoints";

.VoteOnElectionDay-dates,
.VoteOnElectionDay-requirements {
  @include md {
    width: 450px;
  }

  @include xl {
    width: 400px;
  }
}

.VoteOnElectionDay-dates-title,
.VoteOnElectionDay-ride-title {
  margin-bottom: 16px;
  display: block;
  font-weight: bold;
}

.VoteOnElectionDay-date-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.VoteOnElectionDay-requirements-title {
  margin-top: 24px;
  margin-bottom: 16px;
  display: block;
  font-weight: bold;
}

.VoteOnElectionDay-buttons {
  margin: 40px 0;
}

.VoteOnElectionDay-button {
  margin-bottom: 12px;
}

.VoteOnElectionDay-form {
  #can_embed_form {
    border: 0 !important;
    padding: 32px 0 40px 0 !important;

    @include md {
      padding: 40px 35px 64px 35px !important;
    }

    @include xl {
      padding: 0 !important;
      width: 400px !important;
    }
  }

  #can_embed_form_inner h2,
  h4 {
    display: none;
  }

  #logo_wrap {
    display: none;
  }

  #action_info {
    display: none;
  }

  #form_col1 {
    input {
      padding-left: 20px !important;
    }
  }

  #form_col1,
  #form_col2 {
    input {
      height: 60px !important;
      font-family: "HelveticaNeue" !important;
    }
  }

  #can_embed_form.can_float #form_col1,
  #can_embed_form.can_float #form_col2 {
    @include md {
      width: 100% !important;
    }
  }

  #can_embed_form .country_drop_wrap select.can_select {
    height: 60px !important;
  }

  #can_embed_form .floatlabel-wrapper .floatlabel-label {
    color: $medium-gray !important;
  }

  #can_embed_form select.can_select,
  #can_embed_form span.can_select {
    height: 60px !important;
    padding: 0 !important;
    padding-left: 20px !important;
    display: flex !important;
    align-items: center;
    background-position: right -40px top 17px !important;
  }

  #form_col2 {
    display: flex;
    flex-direction: column;

    input {
      background-color: $black !important;
      font-size: 20px !important;
      line-height: 20px !important;
      order: 2;
    }
  }

  #can_embed_form #d_sharing {
    border-top: 0 !important;
  }

  #can_embed_form ul,
  #can_embed_form ol {
    margin: 0 !important;
  }

  #can_embed_form #d_sharing label {
    padding-left: 35px !important;
    margin-bottom: 32px !important;
    margin-top: 20px !important;
    position: static !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: $black !important;

    @include xl {
      margin-bottom: 60px !important;
    }
  }

  #can_embed_form #form_col2 label {
    display: flex !important;
    align-items: center !important;
    font-family: "HelveticaNeue" !important;
  }

  #can_embed_form #form_col2 select {
    margin-bottom: 0 !important;
  }

  #can_embed_form #d_sharing input {
    width: 24px !important;
    height: 24px !important;
  }

  #form_col2 input {
    font-family: Knockout !important;
  }

  #can_embed_form input[type=submit]:hover {
    @media (hover: hover) {
      &:hover {
        background-color: $dark-gray !important;
      }
    }
  }
}

.has-tabbed .VoteOnElectionDay-form #can_embed_form #form_col2 input:focus {
  outline: 1px solid $black !important;
  outline-offset: 4px !important;
}