@import "scss/base/colors";

.LinkStatus-link {
  width: 100%;
  padding: 25px 0;
  display: block;
}

.LinkStatus-inner {
  display: flex;
  justify-content: space-between;
}

.LinkStatus-title {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: left;
  color: $black;
}

.LinkStatus-label {
  color: $medium-gray;
}