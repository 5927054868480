@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.Countdown {
  width: 100%;
  height: 443px;

  @include md {
    height: 570px;
  }

  @include xl {
    height: 580px;
  }
}

.Countdown-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 68px;

  @include md {
    padding-top: 0;
  }

  @include xl {
    padding-top: 80px;
  }
}

.Countdown--red {
  background-color: $red;
}

.Countdown--yellow {
  background-color: $yellow;
}

.Countdown--blue {
  background-color: $blue;
}

.Countdown-title {
  margin-bottom: 5px;
  text-transform: uppercase;

  @include md {
    margin-bottom: 10px;
  }
}

.Countdown-subtitle {
  margin-bottom: 40px;
  text-align: center;
  width: 178px;

  @include md {
    width: 445px;
    padding: 0 105px;
  }

  @include xl {
    margin-bottom: 70px;
  }
}

.Countdown-button {
  width: 200px;
  height: 60px;

  @include md {
    width: 340px;
  }

  @include xl {
    width: 325px;
  }
}