@import "scss/base/colors.scss";

.Button {
  display: block;
  width: 100%;
  height: 60px;
}

.Button--disabled {
  background-color: rgba($black, 0.2);
  cursor: not-allowed;
}

.Button-label {
  display: block;
  text-transform: uppercase;
}

.Button-icon {
  margin-left: 5px;
}

.Button--black {
  background-color: $black;
  color: $white;

  @media (hover: hover) {
    &:hover {
      background-color: $dark-gray;
    }
  }
}

.has-tabbed .Button--black:focus {
  outline: 1px solid $black;
  outline-offset: 4px;
}

.Button--white {
  background-color: $white;
  color: $black;

  @media (hover: hover) {
    &:hover {
      background-color: $light-gray;
    }
  }
}

.has-tabbed .Button--white:focus {
  outline: 1px solid $white;
  outline-offset: 4px;
}

.Button--transparent {
  background-color: transparent;
  color: $black;
  border: 1px solid $black;

  @media (hover: hover) {
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.has-tabbed .Button--transparent:focus {
  background-color: transparent;
  outline: 1px solid $black;
  outline-offset: 4px;
  color: $black;
}

.Button-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button-wrapper--inline {
  display: inline;

  .Button-label {
    display: inline;
  }
}