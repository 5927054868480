@import "scss/base/breakpoints.scss";
@import "scss/base/colors.scss";

.ShareButton {
  display: none;

  @include xl {
    display: block;
    position: absolute;
    top: 12px;
    right: 34px;
    height: 44px;
    width: 144px;
    border: 1px solid $black;
    z-index: 10;

    @media (hover: hover) {
      &:hover {
        background-color: $black;
        color: $white;
      }
    }

    &:focus,
    &:active {
      background-color: $white;
      color: $black;
      border: 0;
    }
  }

  @include xl {
    top: 18px;
    right: 100px;
  }

  svg {
    @include md {
      display: none;
    }
  }
}

.ShareButton--opened {
  @include md {
    background-color: $black;
    color: $white;
    z-index: 10;
  }
}

.ShareButton-label {
  text-transform: uppercase;
}

.ShareButton--transparent {
  background-color: transparent;
  color: $white;
  border: 1px solid $white;

  @media (hover: hover) {
    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  &:focus,
  &:active {
    background-color: transparent;
    outline: 1px solid $white;
    outline-offset: 4px;
    color: $white;
  }
}