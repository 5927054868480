@import "scss/base/colors";
@import "scss/base/breakpoints";

.StepHeader {
  height: 68px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: $black;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  @include md {
    height: 80px;
    padding: 0 34px;
  }

  @include xl {
    padding: 0 100px;
  }
}

.StepHeader-button {
  background-color: transparent;
  border: 1px solid $white;
  color: $white;
  width: 187px;
  position: absolute;
  right: 25px;

  @include md {
    width: 224px;
    height: 44px;
    right: 34px;
  }

  @include xl {
    right: 100px;
  }
}

.StepHeader-icon path {
  fill: $white;
}

.StepHeader-text {
  @include md {
    font-size: 18px;
    line-height: 28px;
  }
}

.StepHeader-logo {
  @include md {
    top: 22px;
  }
}