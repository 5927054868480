@import "scss/base/colors";
@import "scss/base/breakpoints";

.StickyHeader {
  display: block;
  background-color: $white;
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 13;
  padding: 25px;
  transform: translateY(-100%);
  transition: 300ms ease transform;

  @include md {
    padding-left: 35px;
  }

  @include xl {
    display: none;
  }
}

.StickyHeader--show {
  transform: translateY(0);
}
