$breakpoint-sm: 375px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1400px;

@mixin landscape() {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait() {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin sm() {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md() {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg() {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl() {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
