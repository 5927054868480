@import "scss/base/colors";
@import "scss/base/breakpoints";

.OrganizerSection {
  margin: 0 25px 40px 25px;
  position: relative;

  @include md {
    padding-bottom: 80px;
    margin-left: 0;
  }

  @include xl {
    width: 405px;
    margin-left: 62px;
  }
}

.OrganizerSection-title {
  margin-left: 0;
  margin-bottom: 24px;
  color: $black;
  text-transform: uppercase;

  @include md {
    margin-bottom: 32px;
    width: 520px;
    margin-left: auto;
    margin-right: auto;
  }

  @include xl {
    width: 400px;
  }
}

.OrganizerSection--single {
  margin-right: 25px;
  margin-bottom: 40px;
}