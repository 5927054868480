@font-face {
  font-family: "Knockout";
  src: url("../../assets/fonts/Knockout/Knockout-HTF49-Liteweight.otf")
      format("opentype"),
    url("../../assets/fonts/Knockout/Knockout-HTF49-Liteweight.woff2")
      format("woff2"),
    url("../../assets/fonts/Knockout/Knockout-HTF49-Liteweight.woff")
      format("woff");
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("../../assets/fonts/HelveticaNeue/HelveticaNeueBold.otf")
      format("opentype"),
    url("../../assets/fonts/HelveticaNeue/HelveticaNeueBold.woff2")
      format("woff2"),
    url("../../assets/fonts/HelveticaNeue/HelveticaNeueBold.woff")
      format("woff");
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../../assets/fonts/HelveticaNeue/HelveticaNeue.otf")
      format("opentype"),
    url("../../assets/fonts/HelveticaNeue/HelveticaNeue.woff2") format("woff2"),
    url("../../assets/fonts/HelveticaNeue/HelveticaNeue.woff") format("woff");
}

@font-face {
  font-family: "HelveticaNeueMd";
  src: url("../../assets/fonts/HelveticaNeue/HelveticaNeueMd.otf")
      format("opentype"),
    url("../../assets/fonts/HelveticaNeue/HelveticaNeueMd.woff2")
      format("woff2"),
    url("../../assets/fonts/HelveticaNeue/HelveticaNeueMd.woff") format("woff");
}
