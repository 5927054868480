@import "scss/base/breakpoints";

.FindMyPollingPlace {
  padding: 120px 0;

  @include md {
    padding-top: 150px;
  }
}

.FindMyPollingPlace-embed-form {
  @include md {
    padding: 0 9px;
  }

  @include xl {
    padding: 0 75px;
  }
}