@import "scss/base/colors";

.Accordion-inner {
  display: flex;
  justify-content: space-between;
}

.Accordion-title {
  display: block;
  margin-bottom: 5px;
  text-transform: uppercase;
  text-align: left;
}

.Accordion-description {
  color: $medium-gray;
  text-align: left;
  display: block;
}

.Icon {
  transition: transform 500ms ease;
}

.Accordion-button {
  width: 100%;
  padding: 25px 0;

  @media (hover: hover) {
    &:hover {
      .Icon {
        transform: rotate(-90deg);
      }

      .Accordion-title {
        color: $medium-gray;
      }

      .Icon path {
        stroke: $medium-gray;
      }
    }
  }

  &:focus,
  &:active {
    .Icon {
      transform: rotate(-90deg);
    }

    .Accordion-title {
      color: $medium-gray;
    }

    .Icon path {
      stroke: $medium-gray;
    }
  }
}

.Icon--expanded {
  transform: rotate(-180deg);
}

.Accordion-rah:not(:last-of-type) {
  border-bottom: 1px solid $light-gray;
}