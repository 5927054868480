@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.swiper-pagination-bullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $black;
}

.swiper-slide {
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-pagination-bullets {
  margin-bottom: 25px;
  left: 0;
  width: 100%;
  bottom: 10px;

  @include md {
    margin-bottom: 30px;
  }

  :focus {
    outline: 0;
  }
}

.swiper-pagination-bullet {
  margin: 0 4px;

  @include md {
    width: 8px;
    height: 8px;
  }
}
