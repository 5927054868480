@import "scss/base/colors";
@import "scss/base/breakpoints";

.VoteEarly-dates,
.VoteEarly-requirements {
  @include md {
    width: 450px;
  }

  @include xl {
    width: 400px;
  }
}

.VoteEarly-dates-title {
  margin-bottom: 16px;
  display: block;
  font-weight: bold;
}

.VoteEarly-date-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.VoteEarly-requirements-title {
  margin-top: 24px;
  margin-bottom: 16px;
  display: block;
  font-weight: bold;
}

.VoteEarly-buttons {
  margin: 40px 0;
}

.VoteEarly-button {
  margin-bottom: 12px;
}