@import "scss/base/colors";
@import "scss/base/breakpoints.scss";

.StepButton {
  background-color: $light-gray;
  padding: 26px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  color: $black;
  border-radius: 2px;

  @include md {
    padding: 0 20px;
    height: 120px;
  }

  @include xl {
    height: 140px;
  }

  .StepButton-icon {
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    @include xl {
      display: none;
    }
  }

  @media (hover: hover) {
    &:hover {
      .StepButton-icon {
        display: flex;
      }
    }
  }
}

.StepButton--completed {
  background-color: $white;
  border: 2px solid $light-gray;

  .StepButton-icon {
    display: flex;
  }
}

.StepButton-wrapper {
  text-align: left;
}

.StepButton-title {
  display: block;
  padding-right: 5px;
  text-transform: uppercase;
}