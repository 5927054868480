@import "scss/base/colors";
@import "scss/base/breakpoints";

.Logo {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 10;
  padding: 10px 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  @include md {
    left: 25px;
  }

  @include xl {
    position: fixed;
    left: 90px;
    top: 22px;
  }

  path {
    fill: $black;
  }
}

.has-tabbed .Logo:not(.Logo--home):focus {
  outline: $white auto 1px;
}

.Logo--home {
  @include xl {
    position: absolute;
  }
}

.Logo--white path {
  fill: $white;
}