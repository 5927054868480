@import "scss/base/colors";
@import "scss/base/breakpoints";

.AccordionSection {
  padding: 0 25px 40px 25px;

  @include md {
    padding: 0 35px 40px 35px;
  }

  @include xl {
    padding: 0;
    padding-top: 35px;
    width: 400px;
  }
}
