@import "scss/base/breakpoints";

.Header1 {
  font-size: 48px;
  line-height: 48px;
  font-family: Knockout;

  @include md {
    font-size: 88px;
    line-height: 88px;
  }
}

.Header2 {
  font-size: 24px;
  line-height: 24px;
  font-family: Knockout;

  @include md {
    font-size: 32px;
    line-height: 32px;
  }
}

.Header3 {
  font-size: 20px;
  line-height: 20px;
  font-family: Knockout;
}

.BodyTextL {
  font-family: "HelveticaNeue";
  font-size: 14px;
  line-height: 24px;

  @include sm {
    font-size: 16px;
  }

  @include md {
    font-size: 18px;
    line-height: 28px;
  }
}

.BodyTextS {
  font-size: 14px;
  line-height: 24px;
  font-family: "HelveticaNeue";

  @include md {
    font-size: 16px;
  }
}

.BodyTextSLink {
  font-size: 14px;
  line-height: 24px;
  font-family: "HelveticaNeue";
}
