// colors

$black: #181616;
$white: #fff;
$red: #e94343;
$blue: #05b6c4;
$default-focus-blue: #007aff;
$yellow: #f2c541;
$light-gray: #f3f3f3;
$medium-gray: #747474;
$dark-gray: #3c3c3c;