@import "scss/base/breakpoints";

.VoteByMail-dates,
.VoteByMail-requirements {
  @include md {
    width: 450px;
  }

  @include xl {
    width: auto;
  }
}

.VoteByMail-dates-title {
  margin-bottom: 16px;
  display: block;
  font-weight: bold;
}

.VoteByMail-deadline {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.VoteByMail-deadline-title {
  max-width: 240px;

  @include md {
    max-width: 100%;
  }
}

.VoteByMail-requirements-title {
  margin-top: 24px;
  margin-bottom: 16px;
  display: block;
  font-weight: bold;
}

.VoteByMail-CTA {
  margin-top: 70px;
  margin-bottom: 60px;
}