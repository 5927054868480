@import "scss/base/breakpoints";
@import "scss/base/colors";

.IntroSection {
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: hidden;
  padding-top: 135px;

  @include md {
    overflow-y: scroll;
    padding: 0 35px;
    padding-top: 160px;
    padding-bottom: 200px;
    height: 100%;
  }

  @include lg {
    padding-top: 140px;
  }

  @include xl {
    padding-left: 100px;
    padding-top: 200px;
    padding-bottom: 128px;
  }
}

.IntroSection--no-scroll {
  @include xl {
    overflow-y: hidden;
  }
}

.IntroSection-textWrapper {
  width: 255px;

  @include md {
    width: 460px;
  }

  @include xl {
    width: 700px;
  }
}

.IntroSection-title {
  text-transform: uppercase;
  margin-bottom: 12px;
}

.IntroSection-label {
  display: block;
  margin-bottom: 48px;

  @include md {
    margin-bottom: 77px;
    width: 460px;
  }
}

.IntroSelect {
  padding: 13px 0;
  border-bottom: 1px solid $white;
  width: 100%;

  @include md {
    padding: 23px 0;
  }

  @include xl {
    width: 700px;
  }
}

.Intro-county {
  color: rgba($white, 0.5);
}

.Intro-county--selected {
  color: $white;
}

.Intro-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}

.IntroSelect-icon {
  transition: transform 500ms ease;
}

.IntroSelect-icon--transform {
  transition: transform 500ms ease;
  transform: rotate(180deg);
}

.IntroSelect-button {
  width: 100%;
  margin-top: 140px;
  width: 100%;

  @include md {
    width: 340px;
    position: static;
    margin-top: 60px;
  }

  @include xl {
    margin-top: 50px;
    width: 320px;
  }
}

.IntroSelect-button--disabled {
  background-color: $white;
  opacity: 0.2;
}

.has-tabbed .IntroSelect:focus {
  outline: 1px solid $default-focus-blue;
}