@import "scss/base/breakpoints";
@import "scss/base/colors";

.PreForm {
  background-color: $black;
  color: $white;
  min-height: 100vh;
  padding: 130px 25px 90px 25px;

  @include md {
    padding: 188px 35px 250px 35px;
  }

  @include xl {
    padding: 160px 35px 300px 100px;
  }
}

.PreForm-title {
  margin-bottom: 24px;
  text-transform: uppercase;

  @include md {
    margin-bottom: 32px;
  }
}

.PreForm-title-container {
  @include xl {
    padding-right: 200px;
    width: 830px;
  }
}

.PreForm-cta-body-wrapper {
  @include xl {
    padding-right: 200px;
    width: calc(100% - 830px);
  }
}

.PreForm-cta-body-inner-wrapper {
  @include xl {
    margin: 0 auto;
    width: 400px;
  }
}

.Preform-subtitle {
  margin-bottom: 5px;
  text-transform: uppercase;

  @include md {
    margin-bottom: 10px;
  }
}

.Preform-list {
  margin-bottom: 32px;
  margin-left: 20px;
}

.Preform-subtitle {
  margin-top: 20px;
}

.Preform-CTAs {
  margin-top: 70px;

  @include xl {
    margin-top: 50px;
  }
}

.Preform-CTA {
  margin-bottom: 12px;
}

.Preform-CTA--white-border {
  border: 2px solid $white;

  @media (hover: hover) {
    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

.has-tabbed .Preform-CTA--white-border:focus {
  background-color: transparent;
  color: $white;
  outline-offset: 4px;
  outline: 1px solid $white;
}

.Preform-footer {
  border-top: 1px solid $light-gray;
  margin-top: 32px;
  padding-top: 28px;

  @include md {
    bottom: 0;
    height: 180px;
    left: 0;
    padding-left: 35px;
    padding-top: 33px;
    position: absolute;
    width: 100%;
  }

  @include xl {
    display: flex;
    height: 150px;
    margin-top: 0;
    padding-left: 100px;
  }
}

.Preform-footer-link {
  color: $white;
  display: block;
  margin-top: 20px;
  text-decoration: underline;

  @include xl {
    margin-top: 0;
  }
}

.has-tabbed .Preform-footer-link:focus {
  outline: 1px solid $white;
  outline-offset: 4px;
  border: 0;
}

.Preform-footer-title {
  text-transform: uppercase;

  @include md {
    font-size: 24px;
    line-height: 24px;
  }
}

.PreForm-body {
  @include md {
    width: 400px;
  }
}

.Preform-footer-title-wrapper {
  @include md {
    display: flex;
    flex-direction: column;
  }

  &:before {
    background-color: $light-gray;
    content: '';
    display: block;
    height: 1px;
    margin-bottom: 28px;
    width: 100%;

    @include xl {
      display: none;
    }
  }
}

.PreForm-content {
  @include xl {
    display: flex;
  }
}

.Preform-footer-text-wrapper {
  @include md {
    display: flex;
  }

  @include xl {
    margin-right: 120px;
  }
}