@import "scss/base/breakpoints";

.Register {
  padding-bottom: 35px;

  @include xl {
    display: flex;
    height: 100%;
    padding-bottom: 0;
  }
}

.Register-form {
  margin-bottom: 40px;
  margin-top: 32px;
  width: 100%;

  @include md {
    padding: 0 9px;
  }

  @include xl {
    height: 100%;
    margin-left: auto;
    min-height: 100vh;
    overflow-y: scroll;
    padding: 160px 200px 300px 200px;
    width: calc(100% - 630px);
  }
}

.Register-form--completed {
  padding-top: 100px;

  @include md {
    padding-left: 9px;
    padding-top: 160px;
  }

  @include xl {
    margin-left: 0;
    overflow-y: hidden;
    padding: 160px 0 0 75px;
    width: 75%;
  }
}

.Register-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;

  @include xl {
    align-items: center;
    bottom: 40px;
    position: fixed;
    right: 40px;
  }
}

.Register-form-copy {
  display: none;

  @include xl {
    display: block;
    margin-bottom: 40px;
    padding-left: 25px;
  }
}

.Register-form--completed .Register-form-copy {
  display: none;
}