@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.ContactOrganizerBox {
  margin-top: 5px;
  text-align: center;

  @include md {
    width: 515px;
    margin: 0 auto;
  }

  @include xl {
    width: 400px;
  }
}

.ContactOrganizerBox-image-wrapper {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include md {
    width: 200px;
    height: 200px;
  }
}

.ContactOrganizerBox-info {
  display: block;
}

.ContactOrganizerBox-button {
  margin-top: 32px;
  height: 60px;
  border: 1px solid $black;
}

.ContactOrganizerBox-link {
  color: inherit;
  text-decoration: underline;
  display: inline-block;

  @media (hover: hover) {
    &:hover {
      color: $dark-gray;
    }
  }
}

.has-tabbed .ContactOrganizerBox-link:focus {
  outline: 1px solid $black;
  outline-offset: 4px;
}