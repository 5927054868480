@import "scss/base/colors";
@import "scss/base/breakpoints";

.Error {
  align-items: center;
  background-color: $black;
  color: $white;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 0 25px;
  text-align: center;

  @include md {
    padding: 0;
  }
}

.Error-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include md {
    width: 520px;
  }

  @include xl {
    width: 820px;
  }
}

.Error-title {
  margin-bottom: 20px;
  text-transform: uppercase;
}

.Error-text {
  display: block;
  margin: 0 auto 60px auto;
  width: 100%;

  @include md {
    width: 520px;
  }

  @include xl {
    width: 610px;
  }
}

.Error-button {
  align-items: center;
  background-color: $white;
  color: $black;
  display: flex;
  height: 60px;
  justify-content: center;
  text-transform: uppercase;
  width: 100%;

  @media (hover: hover) {
    &:hover {
      background-color: $light-gray;
    }
  }

  @include md {
    width: 340px;
  }

  @include xl {
    width: 400px;
  }
}

.has-tabbed .Error-button:focus {
  outline: 1px solid $white;
  outline-offset: 4px;
}