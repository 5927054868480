@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.Confirmation {
  background-color: $blue;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Confirmation-header-button {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px 110px 25px;

  @include md {
    padding: 0;
    position: absolute;
    right: 34px;
    top: 20px;
  }

  @include xl {
    right: 100px;
    top: 26px;
  }

  @media (hover: hover) {
    &:hover {
      color: $dark-gray;
    }
  }
}

.has-tabbed .Confirmation-header-button:focus {
  outline: 1px solid $black;
  outline-offset: 4px;
}

.Confirmation-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 0 25px 84px 25px;

  @include md {
    padding: 0 34px 60px 34px;
  }

  @include xl {
    align-items: center;
    flex-direction: row;
    padding-bottom: 0;
  }
}

.Confirmation-title {
  text-transform: uppercase;
}

.Confirmation-title-wrapper {
  @include md {
    margin-top: 200px;
  }

  @include xl {
    margin-top: 0;
    padding-left: 66px;
    width: 626px;
  }
}

.Confirmation-start-over {
  font-weight: 700;
  text-decoration: underline;

  @include md {
    font-size: 18px;
    line-height: 18px;
  }
}

.Confirmation-button-wrapper {
  text-align: center;

  @include xl {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: calc(100% - 626px);
  }
}

.Confirmation-button-inner-wrapper {
  @include xl {
    margin: 0 auto;
    padding-top: 0;
    width: 400px;
  }
}

.Confirmation-link {
  margin-bottom: 20px;
}

.Confirmation-link--black {
  color: $black;
  display: inline;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
}

.Confirmation-description-wrapper {
  margin-top: 40px;
  max-width: 300px;

  @include xl {
    margin-bottom: 40px;
    margin-top: 0;
    max-width: 400px;
  }
}

.Confirmation-description {
  margin-bottom: 30px;

  @include md {
    text-align: left;
  }
}

.Confirmation-description-title {
  margin-bottom: 4px;
  text-transform: uppercase;
}

.Confirmation-content--not-centered {
  @include xl {
    align-items: flex-start;
    padding-top: 200px;
  }

  .Confirmation-button-inner-wrapper {
    @include xl {
      align-items: flex-start;
    }
  }
}

.Confirmation-link--commit {
  @media (hover: hover) {
    &:hover {
      color: $dark-gray;
    }
  }
}

.has-tabbed .Confirmation-link--commit:focus {
  outline: 1px solid $black;
  outline-offset: 4px;
}