@import "scss/base/breakpoints";

.RequestAbsenteeBallot {
  padding: 125px 0;

  @include md {
    padding-top: 140px;
  }
}

.RequestAbsenteeBallot-embed-form {
  @include md {
    padding: 0 9px;
  }

  @include xl {
    padding: 0 75px;
  }
}