@import "scss/base/breakpoints";
@import "scss/base/colors";

.Plan {
  padding-bottom: 35px;

  @include xl {
    display: flex;
    height: 100vh;
    padding-bottom: 0;
  }
}

.Plan-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;

  @include md {
    margin-right: 35px;
  }

  @include xl {
    bottom: 40px;
    position: absolute;
    right: 40px;
  }
}

.Plan-text {
  display: none;

  @include xl {
    display: block;
    width: 400px;
  }
}

.Plan-form-text-wrapper {
  @include xl {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 630px;
    padding-top: 160px;
    width: calc(100% - 630px);
  }
}