@import "scss/base/breakpoints.scss";
@import "scss/base/colors.scss";

.Checklist {
  padding: 25px;

  @include md {
    padding-bottom: 60px;
    padding-right: 0;
    padding-left: 0;
    width: 520px;
    margin: 0 auto;
    padding-top: 20px;
  }

  @include xl {
    display: flex;
    margin: 0;
    flex-direction: column;
    width: 100%;
    min-width: 720px;
    padding-bottom: 38px;
    padding-top: 0;
  }
}

.Checklist-container {
  @include xl {
    display: flex;
    width: calc(100% - 400px);
  }
}

.Checklist-line {
  display: none;

  @include xl {
    display: block;
    height: calc(100% - 72px);
    width: 1px;
    background-color: $light-gray;
    margin-left: 62px;
  }
}