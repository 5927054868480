@import "scss/base/colors";
@import "scss/base/breakpoints";

.Commit {
  padding-bottom: 35px;

  @include xl {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-bottom: 0;
  }

  #can_embed_form {
    border: 0 !important;
    padding: 32px 25px 40px 25px !important;

    @include md {
      padding: 40px 35px 64px 35px !important;
    }

    @include xl {
      padding: 0 !important;
      width: 400px !important;
    }
  }

  #can_embed_form_inner h2,
  h4 {
    display: none;
  }

  #logo_wrap {
    display: none;
  }

  #action_info {
    display: none;
  }

  #form_col1 {
    input {
      padding-left: 20px !important;
    }
  }

  #form_col1,
  #form_col2 {
    input {
      height: 60px !important;
      font-family: "HelveticaNeue" !important;
    }
  }

  #can_embed_form.can_float #form_col1,
  #can_embed_form.can_float #form_col2 {
    @include md {
      width: 100% !important;
    }
  }

  #can_embed_form .country_drop_wrap select.can_select {
    height: 60px !important;
  }

  #can_embed_form .floatlabel-wrapper .floatlabel-label {
    color: $medium-gray !important;
  }

  #can_embed_form select.can_select,
  #can_embed_form span.can_select {
    align-items: center;
    background-position: right -40px top 17px !important;
    display: flex !important;
    height: 60px !important;
    padding: 0 0 0 20px !important;
  }

  #form_col2 {
    display: flex;
    flex-direction: column;

    input {
      background-color: $black !important;
      font-size: 20px !important;
      line-height: 20px !important;
      order: 2;
    }
  }

  .has-tabbed #can_embed_form input[type=submit]:focus {
    outline: 1px solid $black !important;
    outline-offset: 4px !important;
  }

  #can_embed_form input[type=submit]:hover {
    @media (hover: hover) {
      background-color: $dark-gray !important;
    }
  }

  #can_embed_form #d_sharing {
    border-top: 0 !important;
  }

  #can_embed_form ul,
  #can_embed_form ol {
    margin: 0 !important;
  }

  #can_embed_form #d_sharing label {
    color: $black !important;
    font-size: 14px !important;
    line-height: 24px !important;
    margin-bottom: 32px !important;
    margin-top: 20px !important;
    padding-left: 35px !important;
    position: static !important;

    @include xl {
      margin-bottom: 60px !important;
    }
  }

  #can_embed_form #form_col2 label {
    align-items: center !important;
    display: flex !important;
    font-family: "HelveticaNeue" !important;
  }

  #can_embed_form #form_col2 select,
  #can_embed_form #form_col2 input {
    margin-bottom: 0 !important;
  }

  #can_embed_form #d_sharing input {
    height: 24px !important;
    width: 24px !important;
  }

  .Commit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;

    @include md {
      margin-right: 35px;
    }

    @include xl {
      bottom: 40px;
      position: absolute;
      right: 40px;
    }
  }

  #form_col2 input {
    font-family: Knockout !important;
  }

  #can_embed_form.can_float #form_col2,
  #can_embed_form.can_float #form_col1 {
    float: left !important;
    width: 100% !important;

    @include md {
      float: right !important;
    }

    @include xl {
      width: 48% !important;
    }
  }
}

.Commit-text {
  display: none;

  @include xl {
    display: block;
    margin-bottom: 40px;
    width: 400px;
  }
}

.Commit-form-text-wrapper {
  @include xl {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 100vh;
    padding-bottom: 120px;
    padding-top: 164px;
    width: calc(100% - 630px);
  }
}