@import "scss/base/breakpoints";
@import "scss/base/colors";

.DownloadBox-involved-label-wrapper {
  display: none;

  @include xl {
    display: block;
  }
}

.DownloadBox-title,
.DownloadBox-text {
  display: block;
  margin-bottom: 5px;

  @include md {
    margin-bottom: 12px;
  }
}

.DownloadBox-title {
  text-transform: uppercase;
}

.DownloadBox-involved-label-wrapper {
  display: none;

  @include xl {
    display: block;
  }
}

.DownloadBox-image-button-wrapper {
  margin-top: 32px;
  margin-left: 25px;
  margin-right: 25px;

  @include md {
    margin-left: 35px;
    margin-right: 35px;
  }

  @include xl {
    margin-left: 0;
    margin-right: 0;
  }

  &:after {
    content: "";
    display: block;
    height: 1px;
    background-color: $light-gray;
    margin-top: 40px;
    padding-left: 34px;
  }
}

.DownloadBox-image-button-wrapper--modal {
  &:after {
    display: none;
  }
}

.DownloadBox-image-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include md {
    justify-content: flex-start;
  }

  @include xl {
    justify-content: space-between;
  }
}

.Downloadbox-image-container {
  width: calc(50% - 7.5px);
  position: relative;

  &:after {
    content: "";
    padding-bottom: 100%;
    display: block;
  }

  @include md {
    width: 250px;
    margin-right: 20px;
  }

  @include xl {
    margin-right: 0;
    width: calc(50% - 10px);
  }
}

.DownloadBox-image {
  position: absolute;
  width: 100%;
  height: 100%;
}

.DownloadBox-button {
  width: 100%;
  height: 60px;
  margin-top: 40px;
}
