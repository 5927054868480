@import "scss/base/colors.scss";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.Loader {
  background-color: $black;
  color: $white;
  font-size: 40px;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: spin 1000ms linear infinite;
  }
}
