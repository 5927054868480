@import "scss/base/colors.scss";

.SkipButton {
  width: 90px;
  height: 40px;
  border-radius: 100px;
  background-color: $light-gray;
  color: $black;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      background-color: $black;
      color: $white;

      .SkipButton-icon path {
        fill: $white;
      }
    }
  }
}

.SkipButton-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SkipButton-text {
  display: block;
  margin-right: 5px;
  font-weight: 700;
}