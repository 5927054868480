@import "scss/base/colors.scss";
@import "scss/base/breakpoints.scss";

.Hero-header-container {
  height: 250px;
  width: 100%;
  background-color: $black;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include md {
    height: 400px;
  }

  @include xl {
    height: 100%;
    width: 630px;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.Hero-header {
  padding: 24px 25px 20px 25px;

  @include md {
    padding: 24px 35px 28px 35px;
  }

  @include xl {
    height: 100%;
    width: 630px;
    justify-content: flex-start;
    padding: 160px 100px 125px 100px;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.Hero-header--commit {
  @include xl {
    overflow-y: scroll;
  }
}

.Hero-header-inner {
  display: flex;
  align-items: center;
}

.Hero-header-logo path {
  fill: $white;
}

.Hero-header-title {
  color: $white;
  text-transform: uppercase;
  font-weight: 300;

  @include xl {
    margin-bottom: 15px;
  }
}

.Hero-content {
  padding: 32px 25px 7px 25px;

  @include md {
    padding-left: 35px;
    padding-right: 35px;
  }

  @include xl {
    display: none;
  }
}

.Hero-content-label {
  margin-bottom: 16px;
  text-transform: uppercase;
  color: $black;
  display: block;
}

.Hero-content-text {
  display: block;
  width: 255px;

  @include md {
    width: 450px;
  }
}

.Hero-header-link {
  width: 135px;
  height: 40px;
  border-radius: 100px;
  background-color: rgba($white, 0.1);
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.Hero-header-link-text {
  display: block;
  margin-left: 3px;
}

.Hero-header-content {
  color: $white;
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  width: 315px;
}

.Hero-header-content-number {
  font-size: 60px;
  line-height: 60px;
  display: block;
  font-family: Knockout;
}

.Hero-header-content-text {
  margin-top: 15px;

  @include xl {
    margin-top: 5px;
  }
}

.Hero-header-content-icon {
  width: 58px;
}