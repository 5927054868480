@import "scss/base/colors";
@import "scss/base/breakpoints";

.Dropdown {
  position: absolute;
  top: 22.5px;
  left: 115px;
  z-index: 10;
  color: $black;
  border-radius: 100px;

  @include md {
    top: 12px;
    left: 135px;
  }

  @include xl {
    top: 19.5px;
    left: 200px;

    @media (hover: hover) {
      &:hover {
        background-color: rgba($black, 0.1);
      }
    }

    &:focus,
    &:active {
      background-color: $black;
      color: $white;
    }
  }
}

.Dropdown-state {
  font-family: "HelveticaNeueMd";
  margin-right: 7px;
  font-weight: 700;
  white-space: nowrap;
}

.Dropdown-button-wrapper {
  display: flex;
  align-items: center;

  @include md {
    padding: 8px 20px;
  }
}

.Dropdown-list {
  position: absolute;
  top: 0;
  left: 0;
  background-color: $black;
  height: 100%;
  width: 100%;
  z-index: 10;

  @include md {
    position: fixed;
    top: 80px;
    width: 100%;
    padding-left: 34px;
  }

  @include xl {
    height: 100%;
    width: 700px;
    left: 100px;
    top: 130px;
    padding-left: 0;
    padding-bottom: 20px;
  }
}

.Dropdown-list-header {
  width: 100%;
  height: 68px;
  color: $white;
  padding: 22px 25px;
  border-bottom: 1px solid rgba($white, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include md {
    width: 100%;
    height: 80px;
    padding: 26px 34px;
  }

  @include xl {
    display: none;
  }
}

.Dropdown-list-header-text {
  font-family: "HelveticaNeueBold"
}

.Dropdown-list-item {
  display: block;
  color: $white;
  text-transform: uppercase;
  margin: 5px 0;
  text-align: left;
  width: 100%;
}

.has-tabbed .Dropdown-list-item:focus {
  outline: 1px solid $default-focus-blue;
}

.Dropdown-list-item-text {
  @include xl {
    color: rgba($white, 0.5);

    @media (hover: hover) {
      &:hover {
        color: $white;
      }
    }
  }
}

.Dropdown-list-content {
  height: 100%;
  overflow-y: scroll;
  padding-top: 70px;
  padding-left: 25px;
  padding-bottom: 10px;

  @include md {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 80px;
  }

  @include xl {
    padding-bottom: 90px;
  }
}

.Dropdown-icon {
  fill: transparent;

  path {
    stroke: $black;
  }
}

.Dropdown {
  @include xl {

    &:focus,
    &:active {
      .Dropdown-icon {
        fill: transparent;

        path {
          stroke: $white;
        }
      }
    }
  }
}

.Dropdown-list-homepage {
  position: static;

  @include xl {
    position: fixed;
    width: 400px;
    height: 420px;
    left: 200px;
    top: 70px;
    padding-left: 20px;
    padding-bottom: 0;

    .Dropdown-list-item-text {
      font-size: 32px;
      line-height: 32px;
    }
  }

  .Dropdown-list {
    @include md {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  .Dropdown-list-content {
    @include md {
      padding-bottom: 0;
      padding-top: 90px;
      overflow-y: hidden;
    }

    @include xl {
      padding-top: 0;
      overflow-y: scroll;
    }
  }
}

.Dropdown-icon--opened {
  transform: rotate(180deg);
}

.Dropdown-wrapper--white {
  .Dropdown {
    color: $white;
  }
}

.Dropdown-icon--white {
  fill: transparent;

  path {
    stroke: $white;
  }
}